import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { AdminRootState } from '../store/reducers';

export function useMe() {
  const auth = useSelector(
    (state: AdminRootState) => state.firebase.auth || null
  );

  const user = useSelector(
    (state: AdminRootState) => state.firestore.ordered.me?.[0] || null
  );

  const queries = [];

  if (auth.uid) {
    queries.push({
      collection: DBCollection.users,
      doc: auth.uid || 'USER_NOT_LOADED',
      storeAs: 'me',
    });
  }

  useFirestoreConnect(queries);

  return {
    ...user,
  };
}
