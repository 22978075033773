import React from 'react';
import { keyBy, sortBy } from 'lodash';
import { IconType } from 'react-icons';
import { FiInbox } from 'react-icons/fi';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { colors } from '@giftery/theme';
import SupplierItem from './SupplierItem';
import { Supplier, User, WithId } from '@giftery/api-interface';
import './SupplierList.scss';
import { PageLoader } from '@giftery/ui/page-loader';
import { useSuppliers } from '../../../hooks/UseSuppliers';

const SupplierList: React.FC = () => {
  const [suppliers, users, loading] = useSuppliers();

  const renderMessage = (Icon: IconType, message: string) => {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="MessageIcon">
          <Icon size={64} color={colors.lowLight[500]} />
        </div>
        <p className="lead">{message}</p>
      </div>
    );
  };

  const supplierSortBy = (supplier: WithId<Supplier>, index: number) => {
    return supplier.status.active === false;
  };

  const renderSuppliers = () => {
    if (loading) {
      return <PageLoader className="bg-primary-50" />;
    }

    if (!suppliers || suppliers.length <= 0) {
      return renderMessage(FiInbox, 'No suppliers have been created yet');
    }
    // We have access to both datastructures
    const supplierUsersById: Record<string, WithId<User>> = keyBy(
      users,
      'supplier'
    );
    return (
      <div className="bg-white overflow-hidden">
        <Scrollbars
          autoHeight
          className="overscroll-contain col-span-12"
          hideTracksWhenNotNeeded={true}
          renderTrackVertical={(props) => (
            <div {...props} className="track-vertical" />
          )}
          autoHeightMax="calc(100vh - 100px)"
          autoHide={true}
        >
          <ul className="divide-y divide-gray-200">
            {sortBy(suppliers, supplierSortBy).map((supplier) => {
              const supplierUser = supplierUsersById[supplier.id];
              return (
                <SupplierItem
                  key={supplier.id}
                  id={supplier.id}
                  supplier={supplier}
                  user={supplierUser}
                />
              );
            })}
          </ul>
        </Scrollbars>
      </div>
    );
  };

  return (
    <div
      className={`px-4 pt-0 w-full flex-grow flex flex-col align-center ${
        !suppliers ? 'justify-center' : 'justify-start'
      }`}
    >
      {renderSuppliers()}
    </div>
  );
};

export default SupplierList;
