import { WithId, PopulatedList, OrderProduct } from '@giftery/api-interface';
import React from 'react';
import './ProductItem.scss';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { ProductLoader } from './ProductLoader';

interface ProductItemProps {
  product: OrderProduct;
}

const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
  if (!product) return <ProductLoader />;

  return (
    <div
      className="grid grid-cols-12 gap-2 py-2 px-0 border-b-1"
      key={product.id}
    >
      <div className="col-span-4 md:col-span-6 text-left flex items-center">
        <p>
          <Link to={`/product/${product.id}`}>{product.name}</Link>
        </p>
      </div>
      <div className="col-span-2 hidden md:flex text-center items-center">
        (Qty: {product.quantity})
      </div>
      <div className="col-span-4 md:col-span-2 text-right flex items-center justify-end">
        <p>{numeral(product.price).format('$0,0.00')}</p>
      </div>
    </div>
  );
};

export default ProductItem;
