import { colors } from '@giftery/theme';
import React from 'react';
import { IconType } from 'react-icons';
import { FiInfo } from 'react-icons/fi';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

export interface INumberCard {
  number: number;
  title: string;
  subtitle: string;
  loading: boolean;
  Icon: IconType;
  url: string;
  unit?: string;
  buttonText?: string;
  action?: () => void;
}

type NumberCardProps = INumberCard;

export const NumberCard: React.FC<NumberCardProps> = ({
  number,
  title,
  subtitle,
  loading,
  unit,
  url,
  children,
  Icon,
}) => {
  return (
    <Link
      to={url}
      className="w-full h-full p-6 pb-12 bg-white text-primary-500 relative flex flex-col items-center text-grey-800 justify-center text-center hover:shadow-md hover:text-grey-800 hover:no-underline"
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z-10">
        {!loading && (
          <Icon
            size={96}
            className="stroke-current text-primary-500 opacity-10"
          />
        )}
      </div>
      <h1 className="z-20 mt-4 text-4xl flex-1 font-bold flex items-end justify-center my-0 text-primary-500">
        {!loading && unit && unit === '$' ? unit : ''}
        {loading ? (
          <Loader type="Oval" color={colors.primary[500]} />
        ) : unit ? (
          number.toFixed(unit && unit === '$' ? 2 : 1)
        ) : (
          number
        )}
        {!loading && unit && unit !== '$' ? unit : ''}
      </h1>
      <h2 className="text-xl my-0 text-center z-20 flex-1 flex items-center justify-start flex-col w-full text-primary-500">
        {title}
      </h2>
      {children}
      <div className="z-20 absolute right-5 bottom-2 text-primary-500">
        <button data-for={`${title}-tip`} data-tip>
          <FiInfo size={24} />
        </button>
        <ReactTooltip id={`${title}-tip`} place="top" effect="solid">
          <div className="w-36">{subtitle}</div>
        </ReactTooltip>
      </div>
    </Link>
  );
};
