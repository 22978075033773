import React, { useEffect, useState } from 'react';
import {
  FiCheckCircle,
  FiLock,
  FiMail,
  FiRefreshCcw,
  FiSave,
  FiTrash2,
  FiUnlock,
  FiUserX,
} from 'react-icons/fi';
import './SupplierItem.scss';
import {
  deleteSupplier,
  restoreSupplier,
  updateSupplier,
} from '../../../actions/Suppliers';
import swal from 'sweetalert2';
import { User, Supplier, WithId, SupplierSecure } from '@giftery/api-interface';
import { format } from 'date-fns';
import { colors } from '@giftery/theme';
import { classNames } from '@giftery/utils';
import toast from 'react-hot-toast';
import { useSupplierSecure } from '../../../hooks';
import { loginAsUser } from '../../../actions/Admin';
import { LoadingButton } from '@giftery/ui/loading-button';
import { cloneDeep } from 'lodash';

interface SupplierItemProps {
  user: WithId<User>;
  supplier: Supplier;
  id: string;
}

const SupplierItem: React.FC<SupplierItemProps> = ({
  user,
  supplier: rawSupplier,
  id,
}) => {
  const [commissionFreeSales, setCommissionFreeSales] = useState(0);
  const [open, setOpen] = useState(false);
  const [loadingToken, setLoadingToken] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [supplier, setSupplier] = useState<Supplier>();
  const [secure, setSecure] = useState<SupplierSecure>();
  const rawSecure = useSupplierSecure(id);

  useEffect(() => {
    if (rawSupplier) setSupplier(rawSupplier);
  }, [rawSupplier]);

  useEffect(() => {
    if (rawSecure) {
      setSecure(rawSecure);
      setCommissionFreeSales(rawSecure.commission.commissionFreeSales || 0);
    }
  }, [rawSecure]);

  if (!user || !supplier || !secure) {
    return null;
  }

  const deleteSupplierId = async () => {
    const result = await swal.fire({
      title: `Are you sure?`,
      text: `Once you delete this supplier, this action cannot be undone!`,
      icon: 'error',
      confirmButtonText: `Yes, Delete This Supplier`,
      cancelButtonText: 'No, Go Back',
      confirmButtonColor: colors.danger,
      showCancelButton: true,
    });

    if (result.isConfirmed) {
      toast.promise(deleteSupplier(id), {
        success: 'Successfully deactivated supplier',
        loading: `Sit tight, we're deactivating this supplier for you`,
        error: `Unabled to deactivate this supplier. Please try again.`,
      });
    }
  };

  const restoreSupplierId = async () => {
    toast.promise(restoreSupplier(id), {
      success: 'Successfully restored supplier account',
      loading: `Sit tight, we're restoring this supplier for you`,
      error: `Unabled to restore this supplier. Please try again.`,
    });
  };

  const saveSupplier = async () => {
    toast.promise(updateSupplier(id, { supplier, secure }), {
      success: 'Successfully updated supplier',
      loading: `Sit tight, we're updating ${supplier.tradingName} for you.`,
      error: `Unabled to update this supplier. Please try again.`,
    });
  };

  const updateComissionFree = async (value: string) => {
    const count = parseInt(value) || 0;
    setCommissionFreeSales(count);
    const secureCopy = cloneDeep(secure);
    secureCopy.commission.commissionFreeSales = count;
    setSecure(secureCopy);
  };

  const loginAsSupplier = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const response = loginAsUser<{ token: string }>(user.id);
    await toast.promise(response, {
      loading: `Doing clever things...`,
      success: `Success! We'll open their portal shortly and log you in automatically.`,
      error: `Oh no! We couln't log you in as this supplier, please try again or contact support if the problem persists.`,
    });
    const token = (await response).data.token;
    if (token) {
      const environment =
        process.env.NODE_ENV === 'production'
          ? 'give-production'
          : 'giftery-development';
      let redirectUrl = `https://supplier.thegiftery.co.nz/login?token=${token}`;
      if (
        environment === 'giftery-development' &&
        window.location.hostname === 'localhost'
      ) {
        redirectUrl = `http://localhost:4300/login?token=${token}`;
      } else if (environment === 'giftery-development') {
        redirectUrl = `https://supplier.beta.thegiftery.nz/login?token=${token}`;
      }
      const base = window.open(redirectUrl, '_blank').focus();
    }
  };

  const renderStatus = () => {
    if (supplier.status.deleted) {
      return (
        <div className="flex items-center">
          <FiUserX
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Deactivated
        </div>
      );
    }
    if (supplier.status.active) {
      return (
        <div className="flex items-center">
          <FiCheckCircle
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
            aria-hidden="true"
          />
          Active
        </div>
      );
    }
    if (supplier.status.suspended) {
      return (
        <div className="flex items-center">
          <FiLock
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
            aria-hidden="true"
          />
          Deactivated
        </div>
      );
    }
  };

  return (
    <li>
      <div
        className={classNames(
          !supplier.status.active ? 'opacity-50' : '',
          'block hover:bg-gray-50'
        )}
      >
        <div className="flex items-center py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-2">
              <div>
                <button
                  onClick={() => setOpen(!open)}
                  className="text-sm font-medium text-primary-600 truncate"
                >
                  {supplier.tradingName || 'Pending'}
                </button>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <FiMail
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="truncate">{user.email}</span>
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="text-sm text-gray-900">
                    Invited on{' '}
                    {format(
                      (
                        supplier.audit.createdAt as FirebaseFirestore.Timestamp
                      ).toDate(),
                      'dd MMM yyyy'
                    )}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    {renderStatus()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {!supplier.status.deleted ? (
              <button onClick={deleteSupplierId}>
                <FiTrash2
                  className="h-5 w-5 text-gray-400 hover:text-primary-500"
                  aria-hidden="true"
                />
              </button>
            ) : (
              <button onClick={restoreSupplierId}>
                <FiRefreshCcw
                  className="h-5 w-5 text-gray-400 hover:text-primary-500"
                  aria-hidden="true"
                />
              </button>
            )}
          </div>
        </div>
        <div
          className={classNames(
            open ? 'h-auto py-4 px-6' : 'h-0',
            'mt-2 flex items-end justify-between text-sm text-gray-500 overflow-hidden w-full'
          )}
        >
          <div className="flex flex-col">
            <label>Remaining commission free sales</label>
            <input
              className="px-4 py-2"
              type="number"
              min={0}
              step={1}
              value={commissionFreeSales}
              onChange={(e) => updateComissionFree(e.target.value || '')}
            />
          </div>
          <div className="flex items-end justify-end">
            <LoadingButton
              loading={loadingToken}
              feedback="Sending Invite"
              className="border-2 border-primary-500 text-primary-500 px-4 py-2 ml-3 h-full flex items-center hover:bg-primary-400 hover:border-primary-400 hover:text-white"
              onClick={loginAsSupplier}
            >
              <FiUnlock /> Login as {supplier.tradingName}
            </LoadingButton>
            <LoadingButton
              loading={loadingUpdate}
              feedback="Sending Invite"
              className="border-2 border-primary-500 bg-primary-500 text-white px-4 py-2 ml-3 h-full flex items-center hover:bg-primary-400  hover:border-primary-400"
              onClick={saveSupplier}
            >
              <FiSave />
              Save
            </LoadingButton>
          </div>
        </div>
      </div>
    </li>
  );
};

export default SupplierItem;
