import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import LoginPage from './components/Auth/Login';
import ReactDOM from 'react-dom';
import { configureAppStore } from './store/store';
import reportWebVitals from './reportWebVitals';
import AppWithSideBar from './containers/AppWithSidebar';
import { Toaster } from 'react-hot-toast';
import { createBrowserHistory } from 'history';
import 'firebase/firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { firebase } from '@giftery/firebase';
import { AuthenticatedRoute, AuthIsLoaded } from '@giftery/ui/auth-check';
import 'tailwindcss/tailwind.css';
import './Variables.scss';
import './Theme.scss';
import DashboardPage from './components/Dashboard/Dashboard';
import SupplierManagementPage from './components/SupplierManagement/SupplierManagement';
import ConfigPage from './components/Config/Config';
import OrderManagementPage from './components/OrderManagement/OrderManagement';

const history = createBrowserHistory();

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  enableClaims: true,
};

firebase.firestore();

const store = configureAppStore(history);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

const App = () => {
  return (
    <Router>
      <AuthIsLoaded>
        <Toaster position="top-right" />
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <AuthenticatedRoute path="/dashboard" roles={['admin']}>
            <AppWithSideBar>
              <DashboardPage />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/configuration" roles={['admin']}>
            <AppWithSideBar>
              <ConfigPage />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/orders" roles={['admin']}>
            <AppWithSideBar>
              <OrderManagementPage />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/suppliers" roles={['admin']}>
            <AppWithSideBar>
              <SupplierManagementPage />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/" roles={['admin']}>
            <Redirect to="/dashboard" />
          </AuthenticatedRoute>
        </Switch>
      </AuthIsLoaded>
    </Router>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
