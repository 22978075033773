import React from 'react';
import { Helmet } from 'react-helmet';
import NewSupplier from './components/NewSupplier';
import SupplierList from './components/SupplierList';

import './SupplierManagement.scss';

const SupplierManagementPage = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>The Giftery | Admin | Supplier Management</title>
      </Helmet>
      <NewSupplier />
      <SupplierList />
    </div>
  );
};

export default SupplierManagementPage;
