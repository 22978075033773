import React, { useEffect, useState } from 'react';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
} from 'react-instantsearch-dom';
import './ProductSearch.scss';
import { storage } from '@giftery/firebase';
import { client } from '@giftery/algolia-client';
import { FiBox } from 'react-icons/fi';
import { SanitizeHTML } from '@giftery/ui/sanitize-html';
import { Product, WithId } from '@giftery/api-interface';
import { Hit } from 'react-instantsearch-core';
import { classNames } from '@giftery/utils';

interface HitProps {
  hit: Hit<WithId<Product>>;
  onClickProduct: (product: WithId<Product>) => void;
}

const HitComponent: React.FC<HitProps> = (props) => {
  const { hit } = props;
  const [image, setImage] = useState<string>();
  useEffect(() => {
    if (!image) {
      const getImage = async () => {
        const mainImage = hit.images[0];
        if (mainImage) {
          let imageUrl = mainImage;
          if (mainImage.indexOf('http') === -1) {
            imageUrl = await storage
              .ref(`/images/${mainImage}`)
              .getDownloadURL();
          }
          setImage(imageUrl);
        }
      };
      getImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, hit]);

  const product = { ...hit, id: hit.objectID };

  return (
    <button
      className="hit border-0 hover:no-underline w-full flex items-center justify-start"
      onClick={() => props.onClickProduct(product)}
    >
      <div className="flex-shrink-0 h-10 w-10">
        {image ? (
          <img
            className="h-10 w-10 rounded-full mr-2"
            src={image}
            alt={product.name}
          />
        ) : (
          <div className="h-10 w-10 rounded-full mr-2 flex items-center justify-center">
            <FiBox size={24} className="stroke-current text-gray-300" />
          </div>
        )}
      </div>
      <div className="col-span-10 no-underline text-grey-500">
        <div className="text-sm font-medium text-gray-900 text-left">
          {product.name}{' '}
          <span className="text-grey-500">(SKU: {product.sku})</span>
        </div>
        <div className="text-sm text-gray-500 text-left">
          <SanitizeHTML
            html={product.description}
            summary={true}
            className="overflow-ellipsis overflow-hidden"
          />
        </div>
      </div>
    </button>
  );
};

interface ProductSearchProps {
  className: string;
  onClickProduct: (product: WithId<Product>) => void;
}

const ProductSearch: React.FC<ProductSearchProps> = ({
  className,
  ...props
}) => {
  const [isSearching, setisSearching] = useState<boolean>(false);
  const onSearchStateChange = (state) => {
    setisSearching(state.query?.length > 0);
  };

  const onClickProduct = (product: WithId<Product>) => {
    props.onClickProduct(product);
    setisSearching(false);
  };

  return (
    <div
      className={classNames('ProductSearchParent rounded-0 w-full', className)}
    >
      <div className="ProductSearch rounded-0 font-sans relative">
        <InstantSearch
          indexName="products"
          searchClient={client}
          onSearchStateChange={onSearchStateChange}
        >
          <Configure filters={`status.active:true AND status.deleted:false`} />
          <SearchBox
            translations={{
              placeholder: 'Search For A Product...',
            }}
          />
          {/* eslint-disable-next-line */}
          {isSearching && (
            <Hits
              hitComponent={({ hit }: { hit: Hit<WithId<Product>> }) => (
                <HitComponent hit={hit} onClickProduct={onClickProduct} />
              )}
            />
          )}
        </InstantSearch>
      </div>
    </div>
  );
};

export default ProductSearch;
