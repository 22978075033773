import React from 'react';
import { Filter, WithId } from '@giftery/api-interface';
import { FiTrash2 } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Scrollbars } from 'react-custom-scrollbars-2';

interface ConfigListProps<T extends WithId<Filter>> {
  loading: boolean;
  items: T[];
  onDelete: (item: T) => void;
  onCreate: (item: Partial<T>) => void;
}

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

export const ConfigFilterList = <T extends WithId<Filter>>({
  loading,
  items,
  ...props
}: ConfigListProps<T>) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: Partial<T>) => {
    props.onCreate(data);
  };
  return (
    <div className="bg-white border-gray-500">
      <Scrollbars
        autoHeight
        className=""
        hideTracksWhenNotNeeded={true}
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        autoHeightMax="50vh"
        autoHide={true}
      >
        <ul className="divide-y divide-gray-200">
          {items?.map((item) => (
            <li key={item.id} className="py-4">
              <div className="flex items-center space-x-4 px-4">
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {item.name}
                  </p>
                </div>
                <div>
                  <button
                    onClick={() => props.onDelete(item)}
                    className="text-danger"
                  >
                    <FiTrash2 />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Scrollbars>
      <form
        onSubmit={handleSubmit<T>(onSubmit)}
        className="p-4 flex items-center justify-between"
      >
        <div className="flex-grow">
          <input
            {...register('name')}
            className="appearance-none block w-full min-w-96 px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          />
          <p>{errors.name?.message}</p>
        </div>
        <button
          disabled={loading}
          className="flex-none bg-primary-500 py-2 px-4 ml-4 w-auto text-white hover:bg-primary-200"
        >
          Add
        </button>
      </form>
    </div>
  );
};
