import { Supplier, User, WithId } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { AdminRootState } from '../store/reducers';

export function useSuppliers(): [WithId<Supplier>[], WithId<User>[], boolean] {
  const users = useSelector(
    (state: AdminRootState) => state.firestore.ordered.users || null
  );

  const suppliers = useSelector(
    (state: AdminRootState) => state.firestore.ordered.suppliers || null
  );

  useFirestoreConnect([
    {
      collection: DBCollection.users,
      where: [['supplier', '!=', null]],
    },
    {
      collection: DBCollection.suppliers,
    },
  ]);

  const loaded = isLoaded(suppliers) && isLoaded(users);

  return [suppliers, users, !loaded];
}
