import React from 'react';
import { Helmet } from 'react-helmet';
import OrderList from './components/OrderList';

import './OrderManagement.scss';

const OrderManagementPage = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>The Giftery | Admin | Order Management</title>
      </Helmet>
      <OrderList />
    </div>
  );
};

export default OrderManagementPage;
