import {
  Product,
  ShowcaseSettings,
  Supplier,
  WithId,
} from '@giftery/api-interface';
import { DBCollection, DBDoc } from '@giftery/enums';
import { Tag } from '@giftery/ui/tagsinput';
import { useSelector } from 'react-redux';
import { chunk } from 'lodash';
import {
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { AdminRootState } from '../store/reducers';

export function useShowcase(): [Tag[], Tag[]] {
  useFirestoreConnect([
    {
      collection: DBCollection.settings,
      doc: DBDoc.showcase || 'LOADING_DOC',
    },
  ]);
  const showcaseSettings = useSelector((state: AdminRootState) => {
    return (
      (state.firestore?.data?.settings?.showcase as ShowcaseSettings) || null
    );
  });
  const homePageQueries: ReduxFirestoreQuerySetting[] =
    showcaseSettings?.homepage.length > 0
      ? chunk(showcaseSettings?.homepage, 10).map((homepageChunk, index) => ({
          collection: DBCollection.products,
          where: [
            '__name__',
            'in',
            homepageChunk.length > 0 ? homepageChunk : ['LOADING_DOC'],
          ],
          storeAs: `showcaseHomepage_${index}`,
        }))
      : [];
  useFirestoreConnect([
    {
      collection: DBCollection.suppliers,
      where: [
        '__name__',
        'in',
        showcaseSettings?.suppliers.length > 0
          ? showcaseSettings.suppliers
          : ['LOADING_DOC'],
      ],
      storeAs: 'showcaseSuppliers',
    },
    ...homePageQueries,
  ]);

  const showcaseSuppliers = useSelector((state: AdminRootState) => {
    return (
      (state.firestore?.ordered?.showcaseSuppliers as WithId<Supplier>[]) ||
      null
    );
  });
  const showcaseHomepage = useSelector((state: AdminRootState) => {
    const one =
      (state.firestore?.ordered?.showcaseHomepage_0 as WithId<Product>[]) || [];
    const two =
      (state.firestore?.ordered?.showcaseHomepage_1 as WithId<Product>[]) || [];
    return [...one, ...two] || null;
  });
  const showcase =
    showcaseSuppliers?.map((supplier) => ({
      name: supplier.tradingName,
      id: supplier.id,
    })) || null;
  const homepage =
    showcaseHomepage?.map((product) => ({
      name: product.name,
      id: product.id,
    })) || null;
  return [showcase, homepage];
}
