import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory } from 'react-router';
import './Login.scss';
import { useFirebase } from 'react-redux-firebase';
import { Logo } from '@giftery/ui/logo';
import { Gifter } from '@giftery/ui/gifter';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const firebase = useFirebase();
  const history = useHistory();
  useEffect(() => {
    firebase.auth().signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const res = await firebase.login({
        email,
        password,
      });
      if (res.user) return history.push('/');
    } catch (err) {
      setError(err.message);
    }
  };

  const renderErrors = () => {
    if (!error) return null;
    return <p className="text-danger">{error}</p>;
  };

  return (
    <div className="min-h-screen bg-white flex w-full">
      <Helmet>
        <title>The Giftery | Login</title>
      </Helmet>
      <div className="w-full flex flex-col mx-auto justify-center py-12 xl:px-24">
        <div className="mx-auto max-w-96">
          <div>
            <Gifter className="h-36 w-36 stroke-current fill-current text-primary-600" />
            <Logo
              className="h-12 stroke-current fill-current text-primary-600"
              color="primary-600"
            />
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={login} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value || '')}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value || '')}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between"></div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
