import { Supplier, SupplierSecure } from '@giftery/api-interface';
import { request } from '@giftery/axios';

type SupplierUpdatePayload = {
  supplier: Partial<Supplier>;
  secure: Partial<SupplierSecure>;
};

export const inviteSupplier = async (email: string) => {
  return await request('post', '/suppliers', { email });
};

export const deleteSupplier = async (id: string) => {
  return await request('delete', `/suppliers/${id}`);
};

export const updateSupplier = async (
  id: string,
  update: SupplierUpdatePayload
) => {
  return await request('put', `/suppliers/${id}`, { ...update });
};

export const restoreSupplier = async (id: string) => {
  return await request('put', `/suppliers/${id}/restore`);
};
