import React, { useState } from 'react';
import { FiMail } from 'react-icons/fi';
import './OrderItem.scss';
import { WithId, Order } from '@giftery/api-interface';
import { format } from 'date-fns';
import { classNames } from '@giftery/utils';
import { map } from 'lodash';
import ProductItem from './ProductItem';
import { resendOrderNotifications } from '../../../actions/Admin';
import toast from 'react-hot-toast';

interface OrderItemProps {
  order: WithId<Order>;
}

const OrderItem: React.FC<OrderItemProps> = ({ order }) => {
  const [open, setOpen] = useState(false);
  if (!order) {
    return null;
  }

  const renderOrderStatus = () => {
    if (order.status.dispatched) {
      return 'Order dispatched by vendor';
    }
    if (order.status.charged) {
      return 'Card charged, awaiting dispatch';
    }
    if (order.status.selected) {
      return 'Gift selected';
    }
    if (order.status.ordered) {
      return 'Ordered';
    }
    return 'Unknown';
  };

  const renderOrderLabel = () => {
    if (!order.listId) {
      return null;
    }
    return (
      <div className="text-sm bg-primary-300 text-primary-500 py-1 px-3 inline font-bold">
        <small>Gift order</small>
      </div>
    );
  };

  const resendOrderEmails = () => {
    const promise = resendOrderNotifications(order.id);
    toast.promise(promise, {
      loading: `Resending order notifications  for order ${order.id}, please wait...`,
      success: `Success, we've resent order notifications for order ${order.id}`,
      error: `Something went wrong when trying to resend order notifications for order ${order.id}`,
    });
  };

  return (
    <li>
      <div className={classNames('block hover:bg-grey-50')}>
        <div className="flex items-center py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-2">
              <div>
                <button
                  className="text-sm font-medium text-primary-600"
                  onClick={() => setOpen(!open)}
                >
                  {order.id}
                </button>
                <div className="text-sm font-medium text-primary-600 truncate">
                  {renderOrderLabel()}
                </div>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <FiMail
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="truncate">
                    {order.logistics.billing.person.email}
                  </span>
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="text-sm bg-primary-300 text-primary-500 py-1 px-3 inline font-bold">
                    <small>{renderOrderStatus()}</small>
                  </div>
                  <div className="text-sm text-gray-900 mt-2">
                    Ordered on{' '}
                    {format(
                      (
                        order.audit.createdAt as FirebaseFirestore.Timestamp
                      ).toDate(),
                      'dd MMM yyyy'
                    )}
                  </div>
                </div>
              </div>
              {order.status.ordered &&
              !order.status.dispatched &&
              !order.status.charged ? (
                <div className="flex items-center justify-end">
                  <button
                    onClick={resendOrderEmails}
                    className="bg-primary-500 text-white px-3 py-1 text-sm"
                  >
                    Resend Order Notifications
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            open ? 'h-auto py-4 px-6' : 'h-0',
            'mt-2 flex items-end justify-between text-sm text-gray-500 overflow-hidden w-full'
          )}
        >
          {/* Order items */}
          <div className="bg-primary-100 p-6">
            <h2>Order Items</h2>
            {map(order.products, (product) => (
              <ProductItem product={product} />
            ))}
          </div>
        </div>
      </div>
    </li>
  );
};

export default OrderItem;
