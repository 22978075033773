import { SupplierSecure, WithId } from '@giftery/api-interface';
import { DBCollection, DBDoc } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { AdminRootState } from '../store/reducers';

export function useSupplierSecure(id: string): SupplierSecure {
  useFirestoreConnect([
    {
      collection: DBCollection.suppliers,
      doc: id || 'NOT_YET_LOADED',
      subcollections: [
        {
          collection: DBCollection.metadata,
          doc: DBDoc.secure,
        },
      ],
      storeAs: `storeSecure_${id}`,
    },
  ]);
  const storeSecure = useSelector((state: AdminRootState) => {
    return state.firestore.data?.[`storeSecure_${id}`] || null;
  });
  return storeSecure;
}
