import React from 'react';
import { chain, sortBy } from 'lodash';
import { IconType } from 'react-icons';
import { FiInbox } from 'react-icons/fi';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { colors } from '@giftery/theme';
import OrderItem from './OrderItem';
import './OrderList.scss';
import { PageLoader } from '@giftery/ui/page-loader';
import { useOrderList } from '../../../hooks';

const OrderList: React.FC = () => {
  const [orders, loading] = useOrderList();

  const renderMessage = (Icon: IconType, message: string) => {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="MessageIcon">
          <Icon size={64} color={colors.lowLight[500]} />
        </div>
        <p className="lead">{message}</p>
      </div>
    );
  };
  const sortedOrders = sortBy(orders, 'audit.createdAt').reverse();

  const renderOrders = () => {
    if (loading) {
      return <PageLoader className="bg-primary-50" />;
    }

    if (!orders || Object.values(orders).length <= 0) {
      return renderMessage(FiInbox, 'No orders have been created yet');
    }

    // We have access to both datastructures
    return (
      <div className="bg-white overflow-hidden">
        <Scrollbars
          autoHeight
          className="overscroll-contain col-span-12"
          hideTracksWhenNotNeeded={true}
          renderTrackVertical={(props) => (
            <div {...props} className="track-vertical" />
          )}
          autoHeightMax="100vh"
          autoHide={true}
        >
          <ul className="divide-y divide-gray-200">
            {sortedOrders.map((order) => {
              return <OrderItem key={order.id} order={order} />;
            })}
          </ul>
        </Scrollbars>
      </div>
    );
  };

  return (
    <div
      className={`px-0 pt-0 w-full flex-grow flex flex-col align-center ${
        !orders ? 'justify-center' : 'justify-start'
      }`}
    >
      {renderOrders()}
    </div>
  );
};

export default OrderList;
