import { request } from '@giftery/axios';

export const loginAsUser = async <T extends Record<string, unknown>>(id) => {
  return await request<T>('get', `/admin/login/as/${id}`);
};

export const resendOrderNotifications = async <
  T extends Record<string, unknown>
>(
  id
) => {
  return await request<T>('get', `/orders/${id}/notify`);
};
