import { ReportSummary, ReportSummarySupplier } from '@giftery/api-interface';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FiDownloadCloud } from 'react-icons/fi';
import { getReportingSummary } from '../../actions/Reporting';
import numeral from 'numeral';
import { CSVDownloader } from 'react-papaparse';
import Loader from 'react-loader-spinner';
import { colors } from '@giftery/theme';

export const MonthlyReportList = () => {
  const [loading, setLoading] = useState(true);
  const [payoutReports, setPayoutReports] = useState<ReportSummary>();
  const [fullReports, setFullReports] = useState<ReportSummary>();

  useEffect(() => {
    const init = async () => {
      const response = await getReportingSummary();
      setPayoutReports(response.data.payouts);
      setFullReports(response.data.full);
      setLoading(false);
    };
    init();
    return () => {
      setPayoutReports(undefined);
      setFullReports(undefined);
    };
  }, []);

  const summarizeReport = (report: Record<string, ReportSummarySupplier>) => {
    const summary = {
      gross: 0,
      net: 0,
    };
    map(report, (r) => {
      summary.gross += r.gross;
      summary.net += r.settlement;
    });
    return summary;
  };

  const downloadPayoutReport = (date: string) => {
    const monthData = payoutReports[date];
    const csvData = map(monthData, (m) => ({
      Supplier: m.name,
      'Bank Account Number': m.bankAccount?.replace('_', ''),
      'GST Number': m.gstNumber,
      'Gross Sales': m.gross,
      Settlement: m.settlement,
      Shipping: m.shipping,
    }));
    return csvData;
  };

  const downloadFullReport = (date: string) => {
    const monthData = fullReports[date];
    const csvData = map(monthData, (m) => ({
      'Order ID': m.orderId,
      Supplier: m.name,
      'Bank Account Number': m.bankAccount.replace('_', ''),
      'GST Number': m.gstNumber,
      'Gross Sales': m.gross,
      Settlement: m.settlement,
      Shipping: m.shipping,
    }));
    return csvData;
  };

  return (
    <div className="py-3">
      <div className="pb-3">
        <h1 className="text-2xl">
          Reporting{' '}
          {loading && (
            <div className="inline-flex items-center justify-center">
              <Loader
                type="Oval"
                color={colors.primary[500]}
                height={20}
                width={20}
              />
            </div>
          )}
        </h1>
        <h2 className="text-lg font-normal">
          Payout reports for each month of trade
        </h2>
      </div>
      {!loading && payoutReports && fullReports && (
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-primary-100">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Month
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Gross Sales
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Commission
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            {map(payoutReports, (report, date) => {
              const summary = summarizeReport(report);
              return (
                <tr key={date} className={'bg-white'}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {date}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {numeral(summary.gross).format('$0,0.00')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {numeral(summary.gross - summary.net).format('$0,0.00')}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex justify-end items-center">
                    <CSVDownloader
                      filename={`Giftery Payout Report - ${date}`}
                      data={() => {
                        return downloadPayoutReport(date);
                      }}
                      className="flex items-center justify-center cursor-pointer hover:text-primary-500 mr-3"
                    >
                      <FiDownloadCloud />
                      &nbsp;Download Payout Report
                    </CSVDownloader>
                    <CSVDownloader
                      filename={`Giftery Full Report - ${date}`}
                      data={() => {
                        return downloadFullReport(date);
                      }}
                      className="flex items-center justify-center cursor-pointer hover:text-primary-500"
                    >
                      <FiDownloadCloud />
                      &nbsp;Download Full Report
                    </CSVDownloader>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
