import React from 'react';
import { Helmet } from 'react-helmet';
import { useOrders } from '../../hooks';

import './Dashboard.scss';
import { MonthlyReportList } from './MonthlyReportList';
import { NumberCards } from './NumberCards/NumberCards';

const DashboardPage = () => {
  return (
    <div className="p-8">
      <Helmet>
        <title>The Giftery | Admin | Dashboard</title>
      </Helmet>
      <NumberCards />
      <MonthlyReportList />
    </div>
  );
};

export default DashboardPage;
