import { sum } from 'lodash';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { AdminRootState } from '../store/reducers';

export interface PlatformStats {
  products: {
    total: number;
    active: number;
    views: number;
    impressions: number;
  };
  suppliers: {
    total: number;
    active: number;
  };
}

export function useStats(): [PlatformStats | null, boolean] {
  useFirestoreConnect([
    {
      collection: DBCollection.products,
    },
    {
      collection: DBCollection.suppliers,
    },
  ]);
  const products = useSelector(
    (state: AdminRootState) => state.firestore.ordered.products || null
  );

  const suppliers = useSelector(
    (state: AdminRootState) => state.firestore.ordered.suppliers || null
  );

  const productViews = sum(products?.map((p) => p.counters?.views ?? 0));
  const productImpressions = sum(
    products?.map((p) => p.counters?.impressions ?? 0)
  );
  const stats: PlatformStats = {
    products: {
      total: products?.length ?? 0,
      active: products?.filter((p) => p.status?.active === true)?.length ?? 0,
      views: productViews ?? 0,
      impressions: productImpressions ?? 0,
    },
    suppliers: {
      total: suppliers?.length ?? 0,
      active: suppliers?.filter((s) => s.status.active)?.length ?? 0,
    },
  };
  return [stats, !isLoaded(products) || !isLoaded(suppliers)];
}
