import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Tag, TagsInput } from '@giftery/ui/tagsinput';
import { useProductMetadata, useShowcase, useSuppliers } from '../../hooks';
import {
  Age,
  Category,
  Filter,
  Hobby,
  Product,
  ShowcaseSettings,
  WithId,
} from '@giftery/api-interface';
import { isEmpty, uniqBy } from 'lodash';
import { updateMetadata } from '../../actions/Config';
import { Scrollbars } from 'react-custom-scrollbars-2';
import toast from 'react-hot-toast';
import { ConfigFilterList } from './components/ConfigList';
import { useFirestore } from 'react-redux-firebase';
import { DBCollection } from '@giftery/enums';
import { capitalizeFirstLetter } from '@giftery/utils';
import ProductSearch from './components/ProductSearch/ProductSearch';

const enum FilterType {
  category = 'category',
  hobby = 'hobby',
  age = 'age',
}

const enum FilterAction {
  add = 'add',
  delete = 'delete',
}

const getFilterLabels = (type: string, name: string, action: FilterAction) => {
  switch (action) {
    case FilterAction.add:
      return {
        loading: `Creating ${type}: ${name}`,
        success: `${capitalizeFirstLetter(type)} "${name}" has been created`,
        error: `Unable to create ${type} "${name}", please try again.`,
      };
    case FilterAction.delete:
      return {
        loading: `Deleting ${type}: ${name}`,
        success: `${capitalizeFirstLetter(type)} "${name}" has been deleted`,
        error: `Unable to delete ${type} "${name}", please try again.`,
      };
    default:
      throw new Error(`Unknown action: ${action}`);
  }
};

const ConfigPage = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<WithId<Category>[]>([]);
  const [hobbies, setHobbies] = useState<WithId<Hobby>[]>([]);
  const [ages, setAges] = useState<WithId<Age>[]>([]);
  const [suppliers, setSuppliers] = useState<Tag[]>([]);
  const [showcaseSuppliers, setShowcaseSuppliers] = useState<Tag[]>([]);
  const [homePageProducts, setHomePageProducts] = useState<Tag[]>([]);
  const [categoryData, hobbyData, ageData] = useProductMetadata();
  const [showcaseSupplierData, showcaseHomePageData] = useShowcase();
  const [supplierData] = useSuppliers();
  const firestore = useFirestore();

  useEffect(() => {
    setCategories(categoryData);
    setHobbies(hobbyData);
    setAges(ageData);
    if (
      !isEmpty(showcaseSupplierData) &&
      showcaseSuppliers.length !== showcaseSupplierData.length
    ) {
      setShowcaseSuppliers(showcaseSupplierData);
    }
    if (
      !isEmpty(showcaseHomePageData) &&
      showcaseHomePageData.length > homePageProducts.length
    ) {
      setHomePageProducts(showcaseHomePageData);
    }
    if (!isEmpty(supplierData) && isEmpty(suppliers)) {
      setSuppliers(
        supplierData.map((supplier) => ({
          name: supplier.tradingName,
          id: supplier.id,
        }))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categoryData,
    hobbyData,
    ageData,
    showcaseSupplierData,
    showcaseHomePageData,
    supplierData,
  ]);

  const reset = () => {
    setCategories(categoryData);
    setHobbies(hobbyData);
    setAges(ageData);
    setShowcaseSuppliers(showcaseSupplierData);
    setSuppliers(
      supplierData.map((supplier) => ({
        name: supplier.tradingName,
        id: supplier.id,
      }))
    );
  };

  const buildFilter = (tags: Tag[]) => {
    const filters = tags.map((tag) => ({
      id: tag.id || tag.name.replace(/[^A-Z0-9]+/gi, '_').toLowerCase(),
      name: tag.name,
    }));
    return filters;
  };

  const save = async () => {
    setLoading(true);
    const updatedCategories = buildFilter(categories);
    const updatedHobbies = buildFilter(hobbies);
    const updatedAges = buildFilter(ages);
    const updatedSupplierShowcase = showcaseSuppliers.map((s) => s.id);
    const updatedHomePageProducts = homePageProducts.map((s) => s.id);
    const promise = updateMetadata({
      categories: updatedCategories,
      hobbies: updatedHobbies,
      ages: updatedAges,
      showcase: updatedSupplierShowcase,
      homepage: updatedHomePageProducts,
    });

    await toast.promise(promise, {
      success: 'Success! Your config has been updated',
      loading: `Updating The Giftery's configuration, please wait...`,
      error: `Unable to update the configuration, please try again.`,
    });
    setLoading(false);
  };

  const updateFilter = async (
    filter: Partial<WithId<Filter>>,
    type: FilterType,
    action: FilterAction
  ) => {
    setLoading(true);
    let collection, data;
    switch (type) {
      case FilterType.category:
        collection = DBCollection.categories;
        data = categoryData;
        break;
      case FilterType.hobby:
        collection = DBCollection.hobbies;
        data = hobbyData;
        break;
      case FilterType.age:
        collection = DBCollection.ages;
        data = ageData;
        break;
      default:
        throw new Error(`Unknown filter type: ${type}`);
    }
    const id = FilterAction.add ? filter.name.toLowerCase() : filter.id;
    if (action === FilterAction.add && data.find((c) => c.id === id)) {
      toast.error(
        `${capitalizeFirstLetter(type)} "${capitalizeFirstLetter(
          filter.name
        )}" already exists`
      );
      return;
    }
    const filterName = capitalizeFirstLetter(filter.name);
    let promise;
    switch (action) {
      case FilterAction.add:
        promise = firestore.collection(collection).doc(id).set({
          name: filterName,
        });
        break;
      case FilterAction.delete:
        promise = firestore.collection(collection).doc(id).delete();
        break;
      default:
        throw new Error(`Unknown filter action: ${action}`);
    }
    await toast.promise(promise, getFilterLabels(type, filterName, action));
    setLoading(false);
  };

  const onCreateFilter = async (filter: Partial<Filter>, type: FilterType) => {
    await updateFilter(filter, type, FilterAction.add);
  };

  const onDeleteFilter = async (filter: Filter, type: FilterType) => {
    await updateFilter(filter, type, FilterAction.delete);
  };

  const onClickSearchProduct = (product: WithId<Product>) => {
    setHomePageProducts(
      uniqBy(
        [...homePageProducts, { id: product.id, name: product.name }],
        'id'
      )
    );
  };
  return (
    <div className="ConfigPage">
      <Helmet>
        <title>The Giftery | Admin | Configure</title>
      </Helmet>

      <Scrollbars
        autoHeight
        className="overscroll-contain wo "
        hideTracksWhenNotNeeded={true}
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        autoHeightMax="100vh"
        autoHide={true}
      >
        <div className="p-6 flex flex-col">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-3">
              <label>Vendors to showcase</label>
              <TagsInput
                containerClassName="bg-white border border-gray-300 rounded-0 p-2"
                inputClassName="border-0 focus:ring-0 py-1"
                data={uniqBy(showcaseSuppliers, 'name')}
                autocompleteData={suppliers}
                initial={uniqBy(showcaseSuppliers, 'name')}
                placeholder={'Add a vendor'}
                max={5}
                onChange={(tags) => {
                  if (tags.length <= 5) setShowcaseSuppliers(tags);
                }}
              />
            </div>
          </div>
        </div>
        <div className="p-6 flex flex-col">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-3">
              <label>Categories</label>
              <ConfigFilterList
                loading={loading}
                items={categories}
                onCreate={(filter: Partial<Filter>) =>
                  onCreateFilter(filter, FilterType.category)
                }
                onDelete={(filter: Filter) =>
                  onDeleteFilter(filter, FilterType.category)
                }
              />
            </div>
            <div className="col-span-3">
              <label>Hobbies</label>
              <ConfigFilterList
                loading={loading}
                items={hobbies}
                onCreate={(filter: Partial<Filter>) =>
                  onCreateFilter(filter, FilterType.hobby)
                }
                onDelete={(filter: Filter) =>
                  onDeleteFilter(filter, FilterType.hobby)
                }
              />
            </div>
            <div className="col-span-3">
              <label>Ages</label>
              <ConfigFilterList
                loading={loading}
                items={ages}
                onCreate={(filter: Partial<Filter>) =>
                  onCreateFilter(filter, FilterType.age)
                }
                onDelete={(filter: Filter) =>
                  onDeleteFilter(filter, FilterType.age)
                }
              />
            </div>
            <div className="col-span-3">
              <label>Products to show on homepage</label>
              <ProductSearch
                onClickProduct={onClickSearchProduct}
                className="mb-2"
              />
              <div className="h-32">
                <TagsInput
                  containerClassName="bg-white border border-gray-300 rounded-0 p-2 min-h-full"
                  inputClassName="border-0 focus:ring-0 py-1"
                  data={homePageProducts}
                  initial={homePageProducts}
                  placeholder={'Add a vendor'}
                  max={20}
                  readonly={true}
                  onChange={(tags) => {
                    if (tags.length <= 20) setHomePageProducts(tags);
                  }}
                />
              </div>
              <br />
            </div>
          </div>
        </div>
      </Scrollbars>
      <section aria-labelledby="billing-history-heading">
        <div className="sm:overflow-hidden">
          <div className="py-4 px-4 flex justify-end sm:px-6">
            <button
              type="button"
              onClick={reset}
              disabled={loading}
              className="ml-5 border-primary-500 border-2
             py-2 px-4 inline-flex justify-center text-sm font-medium text-primary-500
             hover:bg-primary-800 focus:outline-none"
            >
              Reset
            </button>
            <button
              type="button"
              disabled={loading}
              onClick={save}
              className="
            ml-2 bg-primary-500 text border border-transparent
            py-2 px-4 inline-flex justify-center text-sm font-medium text-white
            hover:bg-primary-800 focus:outline-none disabled:opacity-50"
            >
              {loading ? 'Please Wait...' : 'Save'}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ConfigPage;
