import { ReportSummary } from '@giftery/api-interface';
import { request } from '@giftery/axios';

export const getReportingSummary = async () => {
  return await request<{ full: ReportSummary; payouts: ReportSummary }>(
    'get',
    '/reports'
  );
};

export const getMonthReport = async (year: string, month: string) => {
  return await request<{ full: ReportSummary; payouts: ReportSummary }>(
    'get',
    `/reports/${year}/${month}`
  );
};
