import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  FiGrid,
  FiShoppingCart,
  FiSettings,
  FiBox,
  FiUser,
  FiPower,
} from 'react-icons/fi';
import { classNames } from '@giftery/utils';
import { Logo } from '@giftery/ui/logo';

const SidebarMenu = () => {
  const navigation = [
    { name: 'Dashboard', icon: FiGrid, href: '/dashboard' },
    {
      name: 'Supplier Management',
      icon: FiBox,
      href: '/suppliers',
    },
    {
      name: 'Order Management',
      icon: FiShoppingCart,
      href: '/orders',
    },
    {
      name: 'Configuration',
      icon: FiSettings,
      href: '/configuration',
    },
  ];

  const isActive = (href: string): boolean => {
    const pathname = window.location.pathname;
    return pathname.indexOf(href) > -1;
  };

  return (
    <div className="flex flex-col col-span-2 h-0 flex-1 bg-primary-500 min-h-screen max-h-screen">
      <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div className="flex items-center flex-shrink-0 px-4">
          <Logo
            className="max-h-12 fill-current stroke-current text-white"
            height={48}
          />
        </div>
        <nav className="mt-5 flex-1 px-2 space-y-1" aria-label="Sidebar">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={classNames(
                isActive(item.href)
                  ? 'bg-primary-400'
                  : 'text-primary-100 hover:bg-primary-600 hover:bg-opacity-75',
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md text-white hover:no-underline'
              )}
            >
              <item.icon
                className="mr-3 flex-shrink-0 h-6 w-6 text-primary-300"
                aria-hidden="true"
              />
              <span className="flex-1">{item.name}</span>
            </NavLink>
          ))}
        </nav>
      </div>
      <div className="flex-shrink-0 flex border-t border-primary-400 p-4 justify-between">
        <NavLink
          to="/dashboard"
          className="flex-shrink-0 group no-underline hover:no-underline"
        >
          <div className="flex items-center">
            <div>
              <FiUser
                className="inline-block h-9 w-9
                rounded-full stroke-current fill-current text-primary-200 bg-primary-50
                sm:h-6 sm:w-6
                "
              />
            </div>
            <div className="ml-2 hover:no-underline">
              <p className="text-sm font-medium text-white my-0 no-underline group-hover:no-underline hover:no-underline">
                Hi, Emily!
              </p>
            </div>
          </div>
        </NavLink>
        <NavLink
          to="/login"
          className="flex-shrink-0 group text-white flex justify-end items-center text-sm px-2"
        >
          <FiPower title="Sign out" />
          <div className="ml-2">Sign out</div>
        </NavLink>
      </div>
    </div>
  );
};

export default SidebarMenu;
