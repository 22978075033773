import { request } from '@giftery/axios';

export const updateMetadata = async ({
  categories,
  hobbies,
  ages,
  showcase,
  homepage,
}) => {
  return await request('put', '/admin/config/metadata', {
    categories,
    hobbies,
    ages,
    showcase,
    homepage,
  });
};
