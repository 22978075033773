import { Order, OrderStatus, WithId } from '@giftery/api-interface';
import { map, chain } from 'lodash';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, populate, isLoaded } from 'react-redux-firebase';
import { AdminRootState } from '../store/reducers';
import { startOfMonth } from 'date-fns';

export function useOrders(): [Record<string, Order[]> | null, boolean] {
  const populates = [
    { child: 'giftGiver', root: DBCollection.users },
    { child: 'giftReceivers', root: DBCollection.users },
  ];
  const collection = DBCollection.orders;
  const monthStart = startOfMonth(new Date());
  useFirestoreConnect([
    {
      collection: DBCollection.orders,
      where: [['audit.createdAt', '>=', monthStart]],
      populates,
    },
  ]);
  const { orders } = useSelector((state: AdminRootState) => {
    return {
      orders: populate(state.firestore, collection, populates),
    };
  });

  const populatedOrders: WithId<Order>[] = map(orders, (o, id) => {
    return { ...o, id };
  });
  const ordersByStatus: Record<string, Order[]> = {
    ordered: populatedOrders.filter((o) => o.status.ordered),
    selected: populatedOrders.filter((o) => o.status.selected),
    voucher: populatedOrders.filter((o) => o.status.voucher),
    charged: populatedOrders.filter((o) => o.status.charged),
    dispatched: populatedOrders.filter((o) => o.status.dispatched),
    delivered: populatedOrders.filter((o) => o.status.delivered),
    all: populatedOrders,
  };
  return [ordersByStatus, !isLoaded(orders)];
}
