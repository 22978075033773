import { Order, OrderStatus, WithId } from '@giftery/api-interface';
import { map, chain } from 'lodash';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, populate, isLoaded } from 'react-redux-firebase';
import { AdminRootState } from '../store/reducers';
import { startOfMonth, subMonths } from 'date-fns';

export function useOrderList(): [WithId<Order>[], boolean] {
  const populates = [
    { child: 'giftGiver', root: DBCollection.users },
    { child: 'giftReceivers', root: DBCollection.users },
  ];
  const collection = DBCollection.orders;
  const monthStart = subMonths(startOfMonth(new Date()), 2);
  useFirestoreConnect([
    {
      collection: DBCollection.orders,
      where: [['audit.createdAt', '>=', monthStart]],
      populates,
    },
  ]);
  const { orders } = useSelector((state: AdminRootState) => {
    return {
      orders: populate(state.firestore, collection, populates),
    };
  });

  const populatedOrders: WithId<Order>[] = map(orders, (o, id) => {
    return { ...o, id };
  });

  return [populatedOrders, !isLoaded(orders)];
}
