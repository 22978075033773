import { useOrders, useStats } from '../../../hooks';
import React from 'react';
import { map } from 'lodash';
import {
  FiBox,
  FiCheckSquare,
  FiCreditCard,
  FiEye,
  FiMousePointer,
  FiPackage,
  FiShoppingBag,
  FiTruck,
  FiUsers,
} from 'react-icons/fi';
import { INumberCard, NumberCard } from './NumberCard';

export const NumberCards: React.FC = () => {
  const [orders, loading] = useOrders();
  const [stats, statsLoading] = useStats();

  const getTotalOrderValue = () => {
    const orderValues = map(orders.all, (o) => {
      const productValues = map(o.products, (p) => {
        return p.price;
      });
      if (o.listId) return Math.max(...productValues);
      return productValues.reduce((v, p) => (v += p), 0);
    }).reduce((v, p) => (v += p), 0);
    return orderValues || 0;
  };

  const getConfirmedOrderValue = () => {
    const orderValues = map(orders.all, (o) => {
      return map(o.products, (p) => {
        const price = p.variant?.price || p.price;
        return p.status.selected === true ? price : 0;
      }).reduce((v, p) => (v += p), 0);
    }).reduce((v, p) => (v += p), 0);
    return orderValues || 0;
  };
  const getDeliveredOrderValue = () => {
    const orderValues = map(orders.all, (o) => {
      return map(o.products, (p) => {
        const price = p.variant?.price || p.price;
        return p.status.delivered === true ? price : 0;
      }).reduce((v, p) => (v += p), 0);
    }).reduce((v, p) => (v += p), 0);
    return orderValues || 0;
  };

  // const [statsCurrentMonth, statsTotal, loading] = useStats();
  const numberCards: INumberCard[] = [
    {
      title: 'Orders placed',
      subtitle: 'The number of orders placed this month',
      loading,
      number: orders?.all?.length ?? 0,
      Icon: FiShoppingBag,
      url: '/',
    },
    {
      title: 'Gifts selected',
      subtitle: 'The number of orders where giftees have selected a gift',
      loading,
      number: orders?.selected?.length ?? 0,
      Icon: FiCheckSquare,
      url: '/',
    },
    {
      title: 'Vouchers selected',
      subtitle:
        'The number of orders where giftees have selected a gift voucher instead',
      loading,
      number: orders?.voucher?.length ?? 0,
      Icon: FiCreditCard,
      url: '/',
    },
    {
      title: 'Orders dispatched',
      subtitle: 'The number of orders that have been dispatched by suppliers',
      loading,
      number: orders?.dispatched?.length ?? 0,
      Icon: FiTruck,
      url: '/',
    },
    {
      title: 'Orders delivered',
      subtitle: 'The number of orders that have been successfully delivered',
      loading,
      number: orders?.delivered?.length ?? 0,
      Icon: FiPackage,
      url: '/',
    },
  ];
  const statsCards: INumberCard[] = [
    {
      title: 'Suppliers Count',
      subtitle: 'The total number of active suppliers on the platform',
      loading: statsLoading,
      number: stats?.suppliers.active,
      Icon: FiUsers,
      url: '/',
    },
    {
      title: 'Total Products',
      subtitle: 'The total number of products on the platform',
      loading: statsLoading,
      number: stats?.products.active,
      Icon: FiShoppingBag,
      url: '/',
    },
    {
      title: 'Product Impressions',
      subtitle: 'The total number of products shown to users',
      loading: statsLoading,
      number:
        stats?.products.impressions > 1000
          ? stats?.products.impressions / 1000
          : stats?.products.impressions,
      Icon: FiEye,
      url: '/',
      unit: stats?.products.impressions > 1000 ? 'K' : null,
    },
    {
      title: 'Product Views',
      subtitle: 'The total number of products users have clicked on',
      loading: statsLoading,
      number: stats?.products.views,
      Icon: FiMousePointer,
      url: '/',
    },
  ];
  const valueCards: INumberCard[] = [
    {
      title: 'Total Order Volume',
      subtitle: 'The value of orders placed this month.',
      loading,
      number: getTotalOrderValue(),
      Icon: FiShoppingBag,
      url: '/',
      unit: '$',
    },
    {
      title: 'Confirmed Order Volume',
      subtitle:
        'The value of orders placed this month where gifts have been selected.',
      loading,
      number: getConfirmedOrderValue(),
      Icon: FiCheckSquare,
      url: '/',
      unit: '$',
    },
    {
      title: 'Delivered Order Volume',
      subtitle:
        'The value of orders placed this month where gifts have been selected.',
      loading,
      number: getDeliveredOrderValue(),
      Icon: FiBox,
      url: '/',
      unit: '$',
    },
  ];
  return (
    <div className="grid grid-cols-1 gap-2">
      <div className="grid grid-cols-4 gap-2">
        {statsCards.map((card, index) => (
          <NumberCard
            key={`numbercard-${index}`}
            Icon={card.Icon}
            loading={card.loading}
            unit={card.unit}
            url={card.url}
            number={card.number}
            title={card.title}
            subtitle={card.subtitle}
          />
        ))}
      </div>

      <div className="grid grid-cols-5 gap-2">
        {numberCards.map((card, index) => (
          <NumberCard
            key={`numbercard-${index}`}
            Icon={card.Icon}
            loading={card.loading}
            unit={card.unit}
            url={card.url}
            number={card.number}
            title={card.title}
            subtitle={card.subtitle}
          />
        ))}
      </div>
    </div>
  );
};
