import React from 'react';
import Sidebar from './Sidebar/Sidebar';
import Content from './Content';

import './AppWithSidebar.scss';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { DBCollection } from '@giftery/enums';

const AppWithSideBar: React.FC = (props) => {
  const { children, ...rest } = props;
  return (
    <div className="grid grid-cols-12 bg-secondary-100 font-sans">
      <Sidebar />
      <Content>{children}</Content>
    </div>
  );
};

export default compose<React.ComponentType>(
  firestoreConnect(() => [
    {
      collection: DBCollection.categories,
    },
    {
      collection: DBCollection.hobbies,
    },
    {
      collection: DBCollection.ages,
    },
    {
      collection: DBCollection.genders,
    },
  ])
)(AppWithSideBar);
