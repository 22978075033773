import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { inviteSupplier } from '../../../actions/Suppliers';
import { LoadingButton } from '@giftery/ui/loading-button';
import { FiUserPlus } from 'react-icons/fi';

const NewSupplier: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const sendInvite = async () => {
    setLoading(true);

    try {
      await toast.promise(inviteSupplier(email), {
        success: `Invite sent successfully!`,
        error: `Unable to send new supplier invite. Please try again.`,
        loading: `Sit tight! We're creating a new supplier!`,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-end p-4 items-center">
      <input
        name="NewSupplierEmail"
        type="email"
        autoComplete="off"
        value={email}
        className="border-2 border-gray-200 w-80 focus:border-primary-500 focus:outline-none"
        placeholder="e.g. person@supplier.co.nz"
        onChange={(e) => setEmail(e.target.value)}
      />
      <LoadingButton
        loading={loading}
        feedback="Sending Invite"
        className="bg-primary-500 text-white px-4 py-2 ml-3 h-full flex items-center hover:bg-primary-400"
        onClick={sendInvite}
      >
        <FiUserPlus /> Invite Supplier
      </LoadingButton>
    </div>
  );
};

export default NewSupplier;
