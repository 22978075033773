import { colors } from '@giftery/theme';
import React from 'react';
import ContentLoader from 'react-content-loader';

export const ProductLoader = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    className="ProductLoader"
    foregroundColor={'white'}
    backgroundColor={colors.primary[100]}
    {...props}
  >
    <rect x="45" y="12" rx="3" ry="3" width="200" height="6" />
    <circle cx="15" cy="15" r="15" />
    <rect x="265" y="12" rx="3" ry="3" width="400" height="6" />
    <rect x="685" y="12" rx="3" ry="3" width="200" height="6" />
    <rect x="905" y="12" rx="3" ry="3" width="80" height="6" />
    <rect x="1005" y="12" rx="3" ry="3" width="80" height="6" />
    <rect x="1105" y="12" rx="3" ry="3" width="80" height="6" />
  </ContentLoader>
);
